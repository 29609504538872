<div class="dialog-container min-h-96">
  <header>
    <h2>{{ translations?.title }}</h2>
  </header>

  <main>
    @if (!store.loading()) {
      @if (
        (store.uploadSuccess() && !this.lanesUpload) ||
        (store.uploadSuccess() && store.rowsError && !store.rowsError()?.length)
      ) {
        <div class="flex flex-col items-center justify-center">
          <p class="mb-4">
            {{ translations?.uploadSuccessfulParagraph }}
          </p>
          <img
            class="h-28 w-28"
            src="assets/images/booking/circle-checkmark.svg"
          />
        </div>
      }

      @if (
        store.uploadSuccess() && store.rowsError && store.rowsError()?.length
      ) {
        <div class="items-top flex flex-col gap-4">
          <p>{{ store.rowsSuccess ? store.rowsSuccess() : '' }}</p>
          <div class="max-h-40 overflow-scroll">
            <cca-info-banner
              [description]="translations?.someRowsFailed ?? ''"
              flavor="warning"
            ></cca-info-banner>
            <div class="mt-3 text-sm">
              @for (error of store.rowsError(); track $index) {
                <p>{{ error }}</p>
              }
            </div>
          </div>
        </div>
      }
      @if (store.error()) {
        <cca-info-banner
          [description]="translations?.uploadFailedParagraph ?? ''"
          flavor="warning"
        ></cca-info-banner>
        <div class="whitespace-pre-wrap">{{ store.error() }}</div>
      }

      @if (!store.error() && !store.uploadSuccess()) {
        <p class="mb-4">
          <span class="mr-1">{{ translations?.paragraph }}</span>
        </p>
        <cca-file-upload
          class="w-full"
          [isUploading]="store.loading()"
          [uploadSuccess]="store.uploadSuccess()"
          (fileUpload)="store.uploadFiles({ file: $event, metaData: metaData })"
          [allowedFileTypes]="allowedFileTypes"
        ></cca-file-upload>
        <div class="mt-3 flex justify-center">
          <a
            class="cursor-pointer text-cca-green-600"
            (click)="store.downloadReferenceTemplate()"
          >
            {{ translations?.downloadLinkLabel }}
          </a>
        </div>
      }
    } @else {
      <div class="flex w-full flex-col items-center justify-center">
        <p class="mb-4">
          {{ translations?.validatingParagraph }}
        </p>
        <div>
          <cca-spinner class="h-12 w-12"></cca-spinner>
        </div>
      </div>
    }
  </main>

  <footer>
    @if (!store.uploadSuccess()) {
      <button
        mat-stroked-button
        color="accent"
        [mat-dialog-close]="false"
        [disabled]="store.loading()"
      >
        {{ translations?.cancelButton }}
      </button>
    }

    @if (store.uploadSuccess()) {
      <button
        mat-flat-button
        color="primary"
        [mat-dialog-close]="store.data()"
        [disabled]="store.loading()"
      >
        {{ translations?.saveButton }}
      </button>
    }
  </footer>
</div>

<!-- TEMPLATES -->
