import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { OrderDetailV2ViewModel } from '@cca-infra/order-management/v2';
import { RequestDetailV2ViewModel } from '@cca-infra/request-management/v2';
import { CdkUnitPipe } from '@cca-common/cdk';
import { IconComponent } from '../icon';
import { TranslocoModule, provideTranslocoScope } from '@jsverse/transloco';
import { OrderDetailViewModel } from '@cca-infra/order-management/v1';

@Component({
  standalone: true,
  imports: [IconComponent, TranslocoModule, CdkUnitPipe],
  selector: 'cca-drive-info',
  templateUrl: './drive-info.component.html',
  styleUrls: ['./drive-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('orderDetail')],
})
export class DriveInfoComponent {
  @Input() order:
    | OrderDetailV2ViewModel
    | RequestDetailV2ViewModel
    | OrderDetailViewModel
    | undefined;
  @Input() isRequest = false;

  get requestedVehicleTypeCanHaveTailLift() {
    if (this.order && 'requestedVehicleTypeCanHaveTailLift' in this.order) {
      return this.order.requestedVehicleTypeCanHaveTailLift;
    }
    return null;
  }

  get minTemperature() {
    if (this.order && 'minTemperature' in this.order) {
      return this.order.minTemperature;
    }
    return null;
  }

  get maxTemperature() {
    if (this.order && 'maxTemperature' in this.order) {
      return this.order.maxTemperature;
    }
    return null;
  }
}
