/**
 * Event API
 * <p>The Event API is used to log and analyze events.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = EventManagement  1 = FinanceManagement  2 = FleetManagement  3 = LocationManagement  4 = OrderManagement  5 = RequestManagement  6 = TranslationManagement  7 = UserManagement  8 = WarehouseManagement  9 = NotificationService  10 = TenderManagement  11 = TrackingService  12 = TripManagement  13 = JobService  14 = SequenceManagement  15 = LaneManagement  16 = ConversationManagement  17 = DocumentManagement  18 = CargoManagement  19 = ContactManagement  20 = CO3Integration  21 = CarrierOrderManagement  22 = ModalityManagement  23 = DnBIntegration  24 = LegalManagement
 */
export enum InvokeServiceName {
  EventManagement = 0,
  FinanceManagement = 1,
  FleetManagement = 2,
  LocationManagement = 3,
  OrderManagement = 4,
  RequestManagement = 5,
  TranslationManagement = 6,
  UserManagement = 7,
  WarehouseManagement = 8,
  NotificationService = 9,
  TenderManagement = 10,
  TrackingService = 11,
  TripManagement = 12,
  JobService = 13,
  SequenceManagement = 14,
  LaneManagement = 15,
  ConversationManagement = 16,
  DocumentManagement = 17,
  CargoManagement = 18,
  ContactManagement = 19,
  CO3Integration = 20,
  CarrierOrderManagement = 21,
  ModalityManagement = 22,
  DnBIntegration = 23,
  LegalManagement = 24,
}
