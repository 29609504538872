<label
  class="relative flex h-full cursor-pointer overflow-hidden rounded border border-cca-neutrals-300 bg-cca-neutrals-50 px-4 py-3 hover:!border-cca-green-600 hover:!bg-cca-green-50"
  [ngClass]="{
    '!border-cca-green-600': isSelected,
  }"
>
  <div class="flex h-full items-center gap-3">
    <mat-radio-button
      [value]="value"
      color="primary"
      [checked]="isSelected"
      (change)="this.radioChanged.emit(this.value)"
    ></mat-radio-button>

    <cca-action-header
      [action]="action"
      icon="user"
      class="mb-0"
      [flavor]="Flavor.Secondary"
    ></cca-action-header>
  </div>
</label>
