import {
  Injectable,
  Injector,
  inject,
  runInInjectionContext,
} from '@angular/core';
import { SignalRConnection } from './signalr-connection';
import { ChaincargoApiConfigToken } from '@cca-common/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  config = inject(ChaincargoApiConfigToken);
  injector = inject(Injector);

  hubConnections = new Map<string, SignalRConnection>();

  public startConnection(microService: string, hub: string) {
    const hubUrl = this.getHubUrl(microService, hub);

    if (this.hubConnections.has(hubUrl)) {
      const connection = this.hubConnections.get(hubUrl);
      if (connection) {
        return connection;
      }
    }

    return runInInjectionContext(this.injector, () => {
      const newConnection = new SignalRConnection(hubUrl);
      this.hubConnections.set(hubUrl, newConnection);

      newConnection.stopped$.subscribe(() => {
        this.removeConnection(hubUrl);
      });

      return newConnection;
    });
  }

  public removeConnection(connection: string) {
    this.hubConnections.delete(connection);
  }

  getHubUrl(microService: string, hub: string) {
    microService = `${microService}.root`;

    const property = this.getProperty(
      this.config as unknown as Record<string, string | object>,
      microService,
    );

    const hubUrl = `${this.config.rootUrl}${property}${hub}`;
    return hubUrl;
  }

  //copied from base api service to build the URL, there is a better solution for
  getProperty(obj: Record<string, string | object>, path: string) {
    const pathParts = path.split('.');
    let currentObject: Record<string, string | object> | string = obj;
    for (let i = 0, len = path.length; i < len; i++) {
      const currentPath = pathParts[i];
      if (currentPath && currentObject && typeof currentObject === 'object') {
        currentObject = currentObject[currentPath] as
          | Record<string, string | object>
          | string;
      } else {
        break;
      }
    }
    return currentObject as string;
  }
}
