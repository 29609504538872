import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService, HttpContextBuilder } from '@cca-infra/core';
import { Locale } from '@jsverse/transloco-locale';
import { Observable, map } from 'rxjs';
import {
  CreateKeysByGroupRequest,
  TranslationKeyViewModel,
  TranslationRecordViewModel,
} from '../model';
import {
  AdaptPaginationRequest,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
  id,
} from '@cca-infra/common';

export type Language = {
  id: id;
  name: string;
  fullName: string;
  locale: Locale;
  locales: Array<LocaleInfo>;
};

export type LocaleInfo = {
  locale: Locale;
  displayName: string;
};

@Injectable({
  providedIn: 'root',
})
export class TranslationService extends BaseApiService {
  constructor() {
    super(`translation.v1.translation`);
  }

  keysPaginated(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ): Observable<PaginationResponse<unknown>> {
    return this.http.post<PaginationResponse<unknown>>(
      `${this.url}keys/paginated`,
      { ...AdaptPaginationRequest(paginationRequest), ...extraParams },
    );
  }

  keysPaginatedFilters() {
    return this.http.get(`${this.url}keys/paginated-filters`);
  }

  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(`${this.url}language/all`).pipe(
      map((languages) => {
        return [...languages]?.sort(
          (a: Language, b: Language) =>
            a.fullName?.localeCompare(b.fullName) ?? 0,
        );
      }),
    );
  }

  updateLanguageUnit(translation: TranslationRecordViewModel) {
    return this.http.put(`${this.url}record/update`, translation);
  }

  updateLanguageDescription(t: TranslationKeyViewModel) {
    return this.http.put(`${this.url}key/update/description`, {
      description: t.description,
      id: t.id,
    });
  }

  getTranslationsWithGroup(lang: string, group: string) {
    return this.http.get<TranslationRecordViewModel[]>(
      `${this.url}keys/${lang}/${group}`,
      {
        // We can bypass the serviceWorker, authentication and other interceptors for this http call
        context: HttpContextBuilder({
          serviceWorker: false,
          authentication: false,
          noAccess: false,
          notFound: false,
          pagination: false,
        }),
      },
    );
  }

  translationExport() {
    return this.http.get(`${this.url}export`, {
      responseType: 'arraybuffer',
    });
  }

  translationImport(file: File) {
    const formData: FormData = new FormData();
    formData.set('formFile', file, file.name);

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'application/json');

    return this.http.post(`${this.url}import`, formData, {
      headers: headers,
      reportProgress: true,
      observe: 'events',
    });
  }

  keysAddMany(request: CreateKeysByGroupRequest) {
    return this.http.post(`${this.url}keys/add-many/bygroup`, request);
  }

  autoTranslate(password: string) {
    return this.http.post(`${this.url}auto-translate`, {
      password: password,
    });
  }

  clearCache() {
    return this.http.get(`${this.url}clear-cache`);
  }
}
