<div
  class="flex flex-col justify-start gap-4 rounded-xl border border-cca-neutrals-300 bg-white p-4"
  [class.border-cca-red-500]="showErrorState"
>
  <div
    class="flex cursor-pointer items-center justify-start gap-3"
    (click)="toggleAccordion()"
  >
    <cca-icon
      class="flex text-2xl"
      [icon]="accordionOpened() ? 'chevron-up' : 'chevron-down'"
    ></cca-icon>

    <h4 class="text-base font-medium">{{ accordionHeader }}</h4>

    @if (showErrorState) {
      <cca-icon
        icon="circle-exclamation"
        class="ms-auto text-xl text-cca-red-500"
      ></cca-icon>
    }
  </div>

  @if (accordionOpened()) {
    <ng-content></ng-content>
  }

  @if (accordionOpened()) {
    <div class="flex flex-row justify-end">
      <button mat-stroked-button color="warn" (click)="removeItem.emit()">
        {{ accordionDeleteButton }}
      </button>
    </div>
  }
</div>
