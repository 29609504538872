<mat-sidenav-container class="h-full">
  <mat-sidenav
    #drawer
    class="sidenav rounded-r-[1.25rem] border-0 bg-cca-neutrals-200 font-medium text-cca-neutrals-800"
    fixedInViewport
    [attr.role]="(isHandset$ | ngrxPush) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | ngrxPush) ? 'over' : 'side'"
    [opened]="(isHandset$ | ngrxPush) === false"
  >
    <aside class="flex w-full flex-col p-4">
      <div class="relative mb-4 flex w-full items-center">
        <a class="h-14" href="/" routerLink="/">
          <img src="assets/images/Logo.svg" />
        </a>
      </div>
      <span class="solid border border-cca-green-600"></span>
      <nav class="pt-4">
        <span>
          <cca-icon icon="users"></cca-icon>
          Users
        </span>
        <ul>
          <li>
            <a
              href="/docs/users"
              [routerLink]="'/docs/users'"
              routerLinkActive="active"
            >
              <cca-icon [icon]="'arrow-curve-down-right'"></cca-icon>
              Documentation
            </a>
          </li>
          <li>
            <a
              href="/api/users"
              [routerLink]="'/api/users'"
              routerLinkActive="active"
            >
              <cca-icon [icon]="'arrow-curve-down-right'"></cca-icon>
              API
            </a>
          </li>
        </ul>
        <span>
          <cca-icon [icon]="'route'"></cca-icon>
          Lanes
        </span>
        <ul>
          <li>
            <a
              href="/docs/lanes"
              [routerLink]="'/docs/lanes'"
              routerLinkActive="active"
            >
              <cca-icon [icon]="'arrow-curve-down-right'"></cca-icon>
              Documentation
            </a>
          </li>
          <li>
            <a
              href="/api/lanes"
              [routerLink]="'/api/lanes'"
              routerLinkActive="active"
            >
              <cca-icon [icon]="'arrow-curve-down-right'"></cca-icon>
              API
            </a>
          </li>
        </ul>
        <span>
          <cca-icon [icon]="'file-invoice-dollar'"></cca-icon>
          Requests
        </span>
        <ul>
          <li>
            <a
              href="/docs/requests"
              [routerLink]="'/docs/requests'"
              routerLinkActive="active"
            >
              <cca-icon [icon]="'arrow-curve-down-right'"></cca-icon>
              Documentation
            </a>
          </li>
          <li>
            <a
              href="/api/requests"
              [routerLink]="'/api/requests'"
              routerLinkActive="active"
            >
              <cca-icon [icon]="'arrow-curve-down-right'"></cca-icon>
              API
            </a>
          </li>
        </ul>
        <span>
          <cca-icon [icon]="'file-invoice'"></cca-icon>
          Orders
        </span>
        <ul>
          <li>
            <a
              href="/docs/orders"
              [routerLink]="'/docs/orders'"
              routerLinkActive="active"
            >
              <cca-icon [icon]="'arrow-curve-down-right'"></cca-icon>
              Documentation
            </a>
          </li>
          <li>
            <a
              href="/api/orders"
              [routerLink]="'/api/orders'"
              routerLinkActive="active"
            >
              <cca-icon [icon]="'arrow-curve-down-right'"></cca-icon>
              API
            </a>
          </li>
        </ul>
        <span>
          <cca-icon [icon]="'paper-plane'"></cca-icon>
          Webhooks
        </span>
        <ul>
          <li>
            <a
              href="/docs/webhooks"
              [routerLink]="'/docs/webhooks'"
              routerLinkActive="active"
            >
              <cca-icon [icon]="'arrow-curve-down-right'"></cca-icon>
              Documentation
            </a>
          </li>
          <li>
            <a
              href="/api/webhooks"
              [routerLink]="'/api/webhooks'"
              routerLinkActive="active"
            >
              <cca-icon [icon]="'arrow-curve-down-right'"></cca-icon>
              API
            </a>
          </li>
        </ul>
      </nav>
    </aside>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="page-container">
      <mat-toolbar
        class="mat-toolbar sticky top-0 z-40 h-full rounded-b-[1.25rem] bg-white !px-6 shadow-sm"
      >
        <!-- <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="isHandset$ | ngrxPush"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button> -->
        <h1 class="!text-xl !font-bold !text-cca-neutrals-800">{{ title }}</h1>
      </mat-toolbar>
      <!-- Add Content Here -->
      <main
        class="solid rounded-t-[1.25rem] border border-b-0 border-cca-neutrals-300 bg-white p-6"
      >
        <router-outlet #outlet="outlet"></router-outlet>
        <a
          class="absolute -left-7 top-3 flex h-6 w-6 rounded-full bg-cca-green-600 text-center text-white"
          (click)="toggle()"
        >
          <cca-icon icon="chevrons-left"></cca-icon>
        </a>
      </main>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
