<ng-container *transloco="let t; read: 'fileUpload'">
  <div
    class="grid cursor-pointer items-center justify-items-center rounded-xl border border-dashed border-cca-neutrals-700 px-3 py-8 text-cca-neutrals-700 transition-colors hover:bg-cca-neutrals-50"
    [ngClass]="dragging ? 'bg-cca-neutrals-50' : 'bg-cca-neutrals-100'"
    (click)="openFileDialog()"
  >
    <input
      #fileInput
      type="file"
      hidden
      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf
  text/plain, application/pdf, .pages, image/*, video/*"
      (change)="inputFileChanged($event)"
    />
    <img
      class="text-3xl text-cca-neutrals-400"
      src="assets/images/file-upload/file-upload.svg"
      [@DragNDrop]="dragging ? 'hover' : ''"
    />
    <p
      class="mt-6 w-full overflow-hidden whitespace-normal text-center text-base font-normal"
    >
      <span>{{
        translations?.chooseFileOrDragChooseText ??
          t('chooseFileOrDragChooseText')
      }}</span>
      <span class="mr-0.5 text-cca-green-600 underline">
        {{
          translations?.chooseFileOrDragBrowseText ??
            t('chooseFileOrDragBrowseText')
        }}
      </span>
      <span>
        {{
          translations?.chooseFileOrDragYourFilesText ??
            t('chooseFileOrDragYourFilesText')
        }}
      </span>
    </p>

    @if (isUploading) {
      <cca-spinner class="mb-2 h-12 w-12"></cca-spinner>
    } @else {
      @if (uploadSuccess) {
        {{ translations?.successfullyUploaded ?? t('successfullyUploaded') }}
      } @else {
        {{ translations?.noFileSelected ?? t('noFileSelected') }}
      }
    }

    @if (fileToBig) {
      <p>
        {{
          translations?.fileToBig ??
            t('fileToBig', { maxFileSize: maxFileSize / 1024 / 1024 })
        }}
      </p>
    }
    @if (!acceptedFileFormat) {
      <p>
        {{ translations?.wrongFormat ?? t('wrongFormat') }}
      </p>
    }
  </div>

  @if (isUploading) {
    <mat-card class="mt-4">
      <div class="flex gap-4">
        <cca-icon icon="file-lines"></cca-icon>
        <h3 class="font-bold">{{ currentlyUploadingFile?.name ?? '' }}</h3>
      </div>
      <mat-progress-bar color="primary" [value]="uploadProgress">
      </mat-progress-bar>
      <p class="mt-2 text-sm">{{ uploadProgress | number: '1.0-0' }}%</p>
    </mat-card>
  }

  @if (verticalLayout && (documents || files)) {
    @if (documents) {
      @for (document of documents; track document) {
        <mat-card class="mt-4 w-full p-2">
          <div class="flex w-full items-center justify-between gap-4">
            <div class="flex gap-2">
              <cca-icon icon="file-lines"> </cca-icon>
              <h4 class="font-bold">{{ document.filename }}</h4>
            </div>
            <cca-icon class="pt-2" icon="trash-can"></cca-icon>
          </div>
        </mat-card>
      }
    } @else {
      @for (file of files; track file) {
        <div class="mt-4 flex flex-col gap-4">
          <div class="flex w-full items-center justify-between">
            <div class="flex items-center gap-3">
              <cca-icon
                class="text-xl text-cca-neutrals-400"
                icon="file-lines"
              ></cca-icon>
              <h4 class="font-bold text-cca-neutrals-800">
                {{ file.name | textLimiter: 25 }}
              </h4>
            </div>
            <cca-icon
              class="cursor-pointer text-xl"
              (click)="removeAFile(file)"
              icon="trash-can"
            ></cca-icon>
          </div>
        </div>
      }
    }
  }
</ng-container>
