export const coeff = 1000 * 60 * 5;
// export function floorToNearest5Minutes(date: Date) {
//   const rounded = new Date(Math.floor(date.getTime() / coeff) * coeff);
//   return rounded;
// }

export function ceilToNearest5Minutes(date: Date) {
  const rounded = new Date(Math.ceil(date.getTime() / coeff) * coeff);
  return rounded;
}

export function millisTillNextRound5Minutes() {
  const now = new Date();
  return ceilToNearest5Minutes(now).getTime() - now.getTime();
}
