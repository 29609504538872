@if (vertical) {
  <div
    class="flex flex-col justify-center gap-6 overflow-hidden rounded bg-white text-center"
  >
    <img class="mx-auto h-16 w-16" [src]="imageSrc" />
    <h4 class="break-words text-xl font-bold text-cca-neutrals-800">
      {{ title }}
    </h4>
    <p class="text-s break-words font-normal text-cca-neutrals-800">
      {{ description }}
    </p>
  </div>
} @else {
  <div
    class="flex flex-row justify-start gap-6 overflow-hidden rounded bg-white"
  >
    <div class="flex h-16 w-16 justify-center">
      <img class="shrink-0" [src]="imageSrc" />
    </div>
    <div class="flex flex-col justify-center gap-1 overflow-hidden">
      <h4 class="break-words text-xl font-bold text-cca-neutrals-800">
        {{ title }}
      </h4>
      <p class="text-s break-words font-normal text-cca-neutrals-800">
        {{ description }}
      </p>
    </div>
  </div>
}
