<div
  class="flex w-full flex-col items-center justify-center gap-1 rounded-xl bg-cca-neutrals-100 p-2 text-center text-cca-neutrals-800"
>
  <cca-icon icon="info" class="text-2xl text-cca-neutrals-400"></cca-icon>
  <h4 class="text-base font-medium">{{ title }}</h4>
  @if (message) {
    <p class="text-sm text-cca-neutrals-500">
      {{ message }}
    </p>
  }
</div>
