<ng-container *transloco="let t; read: 'booking.generalDialog'">
  <div
    [ngxTippy]="stopsTemplate"
    [tippyClassName]="'stops-overview-tippy'"
    [tippyProps]="{
      placement: 'bottom',
      arrow: true,
      theme: 'light',
      interactive: true,
      appendTo: body,
      trigger: trigger(),
      zIndex: zIndex(),
    }"
  >
    <ng-content></ng-content>
  </div>

  <ng-template #stopsTemplate let-name>
    <div>
      <div class="flex flex-col gap-3 p-4">
        <div class="flex items-center gap-1 self-stretch">
          <div
            class="shrink grow basis-0 text-lg font-bold leading-7 text-cca-neutrals-500"
          >
            {{ t('stopsOverviewTitle') }}
          </div>
        </div>
        <div class="flex max-h-[25.75rem] w-[21rem] flex-col overflow-y-auto">
          @for (
            stop of stops();
            track stop;
            let index = $index;
            let last = $last
          ) {
            @if (!last) {
              <div class="flex gap-4">
                <div class="flex flex-col items-center self-stretch pt-1">
                  <div
                    class="h-3 w-3 rounded-full border-2 border-cca-green-600"
                    [class.!border-cca-purple-400]="
                      stop.stopType === StopType.Delivery
                    "
                  ></div>
                  <div
                    class="w-0.5 shrink grow basis-0 bg-cca-neutrals-300"
                  ></div>
                </div>
                @if (isAddress()) {
                  <div class="flex flex-col gap-1 pb-4">
                    <div
                      class="self-stretch text-base font-medium leading-normal text-cca-neutrals-700"
                    >
                      {{ $any(stop)?.address?.city ?? '-' }},
                      {{ $any(stop)?.address?.countryCode ?? '-' }}
                    </div>
                    @if (isTimesSlotNeededWhenAddress()) {
                      <div class="flex flex-col gap-1 pb-4">
                        <cca-time-window
                          [timeOptionType]="
                            stop.stopType === StopType.Pickup
                              ? pickupTimeIndicationType()
                              : deliveryTimeIndicationType()
                          "
                          [timeWindowStart]="
                            $any(stop)?.timeSlotStart ??
                            $any(stop)?.desiredWindowStart ??
                            $any(stop)?.startTime
                          "
                          [timeWindowEnd]="
                            $any(stop)?.timeslotEnd ??
                            $any(stop)?.desiredWindowEnd ??
                            $any(stop)?.endTime
                          "
                          [timeslotRequired]="!!$any(stop)?.timeSlotIsRequired"
                          [timeslotPreAgreed]="!!$any(stop)?.timeSlotPreAgreed"
                        />
                      </div>
                    }
                    <!-- <div class="flex items-center gap-1">
                        <cca-icon
                          class="text-base font-medium text-cca-green-600"
                          icon="truck"
                        />
                        <div
                          class="text-xs font-normal leading-none text-cca-green-600"
                        >
                          {{ stop.distanceToNextStop ?? '-' }}
                        </div>
                      </div> -->
                  </div>
                } @else {
                  <div class="flex flex-col gap-1 pb-4">
                    <div
                      class="self-stretch text-base font-medium leading-normal text-cca-neutrals-700"
                    >
                      {{ t('desiredStop') }}#{{ index + 1 }} ({{
                        stopLabel(stop.stopType) | transloco
                      }})
                    </div>
                    <cca-time-window
                      [timeOptionType]="
                        stop.stopType === StopType.Pickup
                          ? pickupTimeIndicationType()
                          : deliveryTimeIndicationType()
                      "
                      [timeWindowStart]="
                        $any(stop)?.timeSlotStart ??
                        $any(stop)?.desiredWindowStart ??
                        $any(stop)?.startTime
                      "
                      [timeWindowEnd]="
                        $any(stop)?.timeslotEnd ??
                        $any(stop)?.desiredWindowEnd ??
                        $any(stop)?.endTime
                      "
                      [timeslotRequired]="!!$any(stop)?.timeSlotIsRequired"
                      [timeslotPreAgreed]="!!$any(stop)?.timeSlotPreAgreed"
                    />
                  </div>
                }
              </div>
            } @else {
              <div class="flex gap-4">
                <div class="flex flex-col items-center self-stretch">
                  <div class="h-1 w-0.5 bg-cca-neutrals-300"></div>
                  <div
                    class="h-3 w-3 rounded-full border-2 border-cca-green-600"
                    [class.!border-cca-purple-400]="
                      stop.stopType === StopType.Delivery
                    "
                  ></div>
                </div>

                @if (isAddress()) {
                  <div class="flex flex-col gap-1">
                    <div
                      class="self-stretch text-base font-medium leading-normal text-cca-neutrals-700"
                    >
                      {{ $any(stop)?.address?.city ?? '-' }},
                      {{ $any(stop)?.address?.countryCode ?? '-' }}
                    </div>
                    @if (isTimesSlotNeededWhenAddress()) {
                      <div class="flex flex-col gap-1 pb-4">
                        <cca-time-window
                          [timeOptionType]="
                            stop.stopType === StopType.Pickup
                              ? pickupTimeIndicationType()
                              : deliveryTimeIndicationType()
                          "
                          [timeWindowStart]="
                            $any(stop)?.timeSlotStart ??
                            $any(stop)?.desiredWindowStart ??
                            $any(stop)?.startTime
                          "
                          [timeWindowEnd]="
                            $any(stop)?.timeslotEnd ??
                            $any(stop)?.desiredWindowEnd ??
                            $any(stop)?.endTime
                          "
                          [timeslotRequired]="!!$any(stop)?.timeSlotIsRequired"
                          [timeslotPreAgreed]="!!$any(stop)?.timeSlotPreAgreed"
                        />
                      </div>
                    }
                  </div>
                } @else {
                  <div class="flex flex-col gap-1 pb-4">
                    <div
                      class="self-stretch text-base font-medium leading-normal text-cca-neutrals-700"
                    >
                      {{ t('desiredStop') }}#{{ index + 1 }} ({{
                        stopLabel(stop.stopType) | transloco
                      }})
                    </div>
                    <cca-time-window
                      [timeOptionType]="
                        stop.stopType === StopType.Pickup
                          ? pickupTimeIndicationType()
                          : deliveryTimeIndicationType()
                      "
                      [timeWindowStart]="
                        $any(stop)?.timeSlotStart ??
                        $any(stop)?.desiredWindowStart ??
                        $any(stop)?.startTime
                      "
                      [timeWindowEnd]="
                        $any(stop)?.timeslotEnd ??
                        $any(stop)?.desiredWindowEnd ??
                        $any(stop)?.endTime
                      "
                      [timeslotRequired]="!!$any(stop)?.timeSlotIsRequired"
                      [timeslotPreAgreed]="!!$any(stop)?.timeSlotPreAgreed"
                    />
                  </div>
                }
              </div>
            }
          }
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
