/**
 * User API
 * <p>The User API is used to manage users of the platform. Users van login, change passwords.                                      It will also provide an access and refresh token which is used for other API access.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Booking  2 = Request  4 = Order  8 = User  16 = UserGroup  32 = Location  64 = Fleet  128 = Finance  256 = Translation  512 = Internal  1024 = Mobile  2048 = Warehouse  4096 = Dashboard  8192 = Tender  16384 = Analytic  32768 = Task  65536 = Actions  131072 = CarrierOrder  262144 = Trip  524288 = Analysis  1048576 = Legal  2097152 = EventLog
 */
export enum PermissionGroupType {
  Undefined = 0,
  Booking = 1,
  Request = 2,
  Order = 4,
  User = 8,
  UserGroup = 16,
  Location = 32,
  Fleet = 64,
  Finance = 128,
  Translation = 256,
  Internal = 512,
  Mobile = 1024,
  Warehouse = 2048,
  Dashboard = 4096,
  Tender = 8192,
  Analytic = 16384,
  Task = 32768,
  Actions = 65536,
  CarrierOrder = 131072,
  Trip = 262144,
  Analysis = 524288,
  Legal = 1048576,
  EventLog = 2097152,
}
