<ng-container ng-container *transloco="let t; read: 'booking.failure'">
  <div class="flex flex-col items-center justify-center">
    <img [src]="src" alt="trolley" class="mb-6" />
    <div class="max-w-[40rem] text-center">
      <h1 class="mb-5 text-2xl font-bold text-cca-neutrals-800">
        {{ title }}
      </h1>
      <p class="mb-5 text-cca-neutrals-800">{{ paragraph }}</p>
      @if (showButtons) {
        <div class="flex flex-row justify-center gap-4">
          <button mat-stroked-button color="primary">
            <a href="https://ctrlchain.com/en/contact-us" target="_blank">
              {{ t('contactUs') }}
            </a>
          </button>
          <button mat-flat-button color="primary">
            <a
              href="{{ environment.api?.rootUrl }}dashboard/shipper"
              target="_blank"
            >
              {{ t('dashboardLink') }}
            </a>
          </button>
        </div>
      }
    </div>
    <ng-content></ng-content>
  </div>
</ng-container>
