<div class="flex" [ngClass]="smallSize ? 'gap-2' : 'gap-3'">
  <div
    class="grid place-content-center rounded-full border border-cca-green-200 bg-cca-green-50"
    [ngClass]="smallSize ? 'h-8 w-8 min-w-8' : 'h-12 w-12'"
  >
    <cca-icon
      [icon]="icon"
      class="text-cca-green-600"
      [ngClass]="smallSize ? 'text-base' : 'text-2xl'"
    ></cca-icon>
  </div>
  <div class="overflow-hidden">
    <p [ngClass]="smallSize ? 'text-2xs' : 'text-sm'">{{ label }}</p>
    <p class="font-medium" [ngClass]="smallSize ? 'text-xs' : 'text-lg'">
      <span>{{ value }}</span>
      {{ symbol }}
    </p>
  </div>
</div>
