import { inject } from '@angular/core';
import { rootUrlToken } from '../tokens/root-url.token';
import type { ChaincargoApiConfig } from './api.config';

export const defaultConfig = (): ChaincargoApiConfig => {
  const rootUrl = inject(rootUrlToken);

  return {
    rootUrl: rootUrl,
    warehouse: {
      root: 'warehousemanagement/',
      v1: {
        warehouse: 'warehousemanagement/api/v1.0/Warehouse/',
        configuration: 'warehousemanagement/api/v1.0/WarehouseConfiguration/',
      },
    },
    order: {
      root: 'ordermanagement/',
      v1: {
        orders: 'ordermanagement/api/v1.0/Orders/',
        actions: 'ordermanagement/api/v1.0/Actions/',
        receipts: 'ordermanagement/api/v1.0/Receipts/',
        orderActions: 'ordermanagement/api/v1.0/OrderActions/',
        cargo: 'ordermanagement/api/v1.0/Cargo/',
        warehouse: 'ordermanagement/api/v1.0/Warehouse/',
        public: 'ordermanagement/api/v1.0/Public/',
        difot: 'ordermanagement/api/v1.0/DIFOT/',
        multimodal: 'ordermanagement/api/v1.0/Multimodal/',
      },
      v2: {
        orderActions: 'ordermanagement/api/v2.0/OrderActions/',
        orders: 'ordermanagement/api/v2.0/Orders/',
        public: 'ordermanagement/api/v2.0/Public/',
      },
    },
    carrierOrder: {
      root: 'carrierordermanagement',
      v1: {
        carrierOrder: 'carrierordermanagement/api/v1.0/CarrierOrder/',
      },
    },
    user: {
      root: 'usermanagement/',
      v1: {
        authentication: 'usermanagement/api/v1.0/auth/',
        usergroup: 'usermanagement/api/v1.0/usergroup/',
        legal: 'usermanagement/api/v1.0/legal/',
        user: 'usermanagement/api/v1.0/user/',
        apiKey: 'usermanagement/api/v1.0/APIKey/',
        relation: 'usermanagement/api/v1.0/relation/',
      },
      v2: {
        userGroup: 'usermanagement/api/v2.0/usergroup/',
        user: 'usermanagement/api/v2.0/user/',
      },
      v3: {
        userGroup: 'usermanagement/api/v3.0/usergroup/',
        user: 'usermanagement/api/v3.0/user/',
      },
    },
    logging: {
      root: 'eventmanagement/',
      v1: {
        logging: 'eventmanagement/api/v1.0/Error/',
        url: 'eventmanagement/api/v1.0/Url/',
      },
    },
    request: {
      root: 'requestmanagement/',
      v1: {
        booking: 'requestmanagement/api/v1.0/Booking/',
        quotation: 'requestmanagement/api/v1.0/Quotation/',
        request: 'requestmanagement/api/v1.0/Request/',
        transport: 'requestmanagement/api/v1.0/Transport/',
        systemSettings: 'requestmanagement/api/v1.0/SystemSettings/',
        laneRequest: 'requestmanagement/api/v1.0/LanesRequest/',
        multimodal: 'requestmanagement/api/v1.0/Multimodal/',
      },
      v2: {
        request: 'requestmanagement/api/v2.0/Request/',
        laneRequest: 'requestmanagement/api/v2.0/LanesRequest/',
      },
    },
    finance: {
      root: 'financemanagement/',
      v1: {
        price: 'financemanagement/api/v1.0/Price/',
        article: 'financemanagement/api/v1.0/Article/',
        set: 'financemanagement/api/v1.0/Set/',
        articleGroup: 'financemanagement/api/v1.0/Article/group/',
        invoice: 'financemanagement/api/v1.0/Invoice/',
      },
    },
    fleet: {
      root: 'fleetmanagement/',
      v1: {
        cargo: 'fleetmanagement/api/v1.0/Cargo/',
        fleet: 'fleetmanagement/api/v1.0/Fleet/',
        enumDefinition: 'fleetmanagement/api/v1.0/EnumDefinition/',
        vehicle: 'fleetmanagement/api/v1.0/Vehicles/',
      },
      v2: {
        fleet: 'fleetmanagement/api/v2.0/Fleet/',
      },
    },
    location: {
      root: 'locationmanagement/',
      v1: {
        address: 'locationmanagement/api/v1.0/location/address/',
        addressBook: 'locationmanagement/api/v1.0/AddressBook/',
        carrierlane: 'locationmanagement/api/v1.0/CarrierLane/',
      },
    },
    lane: {
      root: 'lanemanagement/',
      v1: {
        lane: 'lanemanagement/api/v1.0/Lane/',
        laneCarrier: 'lanemanagement/api/v1.0/LaneCarrier/',
        laneCommunication: 'lanemanagement/api/v1.0/LaneCommunication/',
        laneInstruction: 'lanemanagement/api/v1.0/LaneInstruction/',
      },
      v2: {
        lane: 'lanemanagement/api/v2.0/Lane/',
      },
    },
    translation: {
      root: 'translationmanagement/',
      v1: {
        translation: 'translationmanagement/api/v1.0/Translation/',
        glossary: 'translationmanagement/api/v1.0/Glossary/',
        caching: 'translationmanagement/api/v1/Caching/',
      },
    },
    tracking: {
      root: 'trackingservice/',
      v1: {
        location: 'trackingservice/api/v1.0/Location/',
        history: 'trackingservice/api/v1.0/History/',
        geofence: 'trackingservice/api/v1.0/Geofence/',
        simulation: 'trackingsimulation/api/v1.0/Simulation/',
      },
    },
    trip: {
      root: 'tripmanagement/',
      v1: {
        planning: 'tripmanagement/api/v1.0/Planning/',
        planningUnits: 'tripmanagement/api/v1.0/PlanningUnits/',
      },
    },
    tender: {
      root: 'tendermanagement/',
      v1: {
        request: 'tendermanagement/api/v1.0/Request/',
        analytics: 'tendermanagement/api/v1.0/Analytics/',
        lane: 'tendermanagement/api/v1.0/Lanes/',
      },
      v2: {
        request: 'tendermanagement/api/v2.0/Request/',
        lane: 'tendermanagement/api/v2.0/Lanes/',
      },
    },
    notification: {
      root: 'notificationservice/',
      v1: {
        email: 'notificationservice/api/v1.0/Email/',
        systemSettings: 'notificationservice/api/v1.0/SystemSettings/',
        notification: 'notificationservice/api/v1.0/Notification/',
        webNotification: 'notificationservice/api/v1.0/WebNotification/',
      },
    },
    settings: {
      root: 'mobilemanagement/',
      v1: {
        mobile: 'mobilemanagement/api/v1.0/MobileSystemSettings/',
      },
    },
    sequence: {
      root: 'sequencemanagement/',
      v1: {
        userState: 'sequencemanagement/api/v1.0/UserState/',
        dev: 'sequencemanagement/api/v1.0/Dev/',
        options: 'sequencemanagement/api/v1.0/Options/',
        utility: 'sequencemanagement/api/v1.0/Utility/',
        preload: 'sequencemanagement/api/v1.0/PreLoad/',
      },
      v2: {
        options: 'sequencemanagement/api/v2.0/Options/',
      },
    },
    conversation: {
      root: 'conversationmanagement/',
      v1: {
        conversationThreads: 'conversationmanagement/api/v1/Thread/',
      },
    },
    documents: {
      root: 'documentmanagement/',
      v1: {
        documents: 'documentmanagement/api/v1/Document/',
      },
    },
    cargo: {
      root: 'cargomanagement/',
      v1: {
        handleUnit: 'cargomanagement/api/v1/HandleUnit/',
        hazardCode: 'cargomanagement/api/v1/HazardCode/',
        value: 'cargomanagement/api/v1/Value/',
      },
    },
    task: {
      root: 'taskmanagement/',
      v1: {
        task: 'taskmanagement/api/v1/Task/',
      },
    },
    dashboard: {
      root: 'dashboardmanagement/',
      v1: {
        filterPreset: 'dashboardmanagement/api/v1.0/FilterPreset/',
      },
    },
    contact: {
      root: 'contactmanagement/',
      v1: {
        externalContact: 'contactmanagement/api/v1/ExternalContact/',
      },
    },

    modality: {
      root: 'modalitymanagement',
      v1: {
        entity: 'modalitymanagement/api/v1/Entity/',
        actions: 'modalitymanagement/api/v1/Actions/',
        actionConfiguration: 'modalitymanagement/api/v1/ActionConfiguration/',
        public: 'modalitymanagement/api/v1/Public/',
      },
    },

    legal: {
      root: 'legalmanagement',
      v1: {
        editor: 'legalmanagement/api/v1/Editor/',
        acceptance: 'legalmanagement/api/v1/Acceptance/',
      },
    },
  };
};
