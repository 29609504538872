import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable, inject, makeEnvironmentProviders } from '@angular/core';
import { REQUEST } from '@cca-common/environment';

/**
 * This cookie interceptor is needed for SSR, when making a connection to the server side angular application
 * We don't have the cookies ourself, but the express REQUEST does have it
 * so when making a new http request to the backend, we should copy over these cookies
 */
@Injectable()
export class CookieInterceptor implements HttpInterceptor {
  private request = inject(REQUEST, {
    optional: true,
  });

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.request) {
      return next.handle(req);
    }

    if (this.request.headers.cookie) {
      req = req.clone({
        setHeaders: {
          Cookie: this.request.headers.cookie,
        },
      });
    }

    return next.handle(req);
  }
}

export function provideCookieInterceptor() {
  return makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CookieInterceptor,
      multi: true,
    },
  ]);
}
