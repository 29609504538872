import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';

export interface TermsAndConditionsData {
  title?: string;
  subTitle?: string;
  documentType?: number;
  paragraphs?: TermsAndConditionsParagraph[];
  translateGroup?: string;
  hasBeenTranslated?: boolean;
}

export interface TermsAndConditionsParagraph {
  title: string;
  text: string;
  index: number;
}

@Injectable({
  providedIn: 'root',
})
export class LegalService extends BaseApiService {
  constructor() {
    super(`user.v1.legal`);
  }

  carrierTerms(countryCode: string | null | undefined) {
    return this.http.get<TermsAndConditionsData>(
      `${this.url}terms-conditions-carrier/${countryCode}`,
    );
  }

  shipperTerms(countryCode: string | null | undefined) {
    return this.http.get<TermsAndConditionsData>(
      `${this.url}terms-conditions-shipper/${countryCode}`,
    );
  }
}
