<div class="flex w-full items-start gap-5">
  <div
    class="mt-[0.1875rem] grid h-10 w-10 flex-shrink-0 place-items-center overflow-hidden rounded-full text-base font-medium text-cca-purple-700"
    [ngClass]="noContact ? 'bg-cca-neutrals-200' : 'bg-cca-purple-100'"
  >
    @if (noContact) {
      <cca-icon class="text-lg text-cca-neutrals-800" icon="user"></cca-icon>
    } @else {
      @if (avatarURL) {
        <img [src]="avatarURL" />
      } @else if (initials) {
        {{ initials }}
      } @else {
        <cca-icon icon="user" class="text-2xl" />
      }
    }
  </div>

  <div class="flex grow flex-col gap-2 text-sm text-cca-neutrals-800">
    <div class="flex flex-shrink-0 flex-col gap-1 font-medium empty:hidden">
      @if (noContact || fullName) {
        <p
          class="break-all text-base"
          [ngClass]="
            noContact ? 'text-cca-neutrals-500' : 'text-cca-neutrals-800'
          "
        >
          @if (noContact) {
            {{ noContactText }}
          } @else {
            {{ fullName }}
          }
        </p>
      }
      @if (flagImageUrl || responsibilities) {
        <p class="flex items-center gap-2 text-xs text-cca-neutrals-500">
          @if (flagImageUrl) {
            <cca-country-flag
              class="max-h-3 max-w-3"
              [url]="flagImageUrl"
            ></cca-country-flag>
          }
          {{ responsibilities ?? '-' }}
        </p>
      }
    </div>
    @if (enterpriseName) {
      <div class="flex items-center gap-2 break-all font-normal">
        <cca-icon icon="enterprise"></cca-icon>
        <span>
          {{ enterpriseName }}
        </span>
      </div>
    }
    @if (groupName) {
      <div class="flex items-center gap-2 break-all font-normal">
        <cca-icon icon="group"></cca-icon>
        <span>
          {{ groupName }}
        </span>
      </div>
    }
    <div class="flex flex-wrap gap-x-4 gap-y-2">
      @if (phoneNumber) {
        <div class="flex grow items-center gap-2">
          <cca-icon icon="phone"></cca-icon>
          <a
            href="tel:{{ phoneNumber }}"
            class="font-medium text-cca-green-600 underline"
            >{{ phoneNumber }}</a
          >
        </div>
      }
      @if (email) {
        <div class="flex grow items-center gap-2">
          <cca-icon icon="mail"></cca-icon>
          <a
            href="mailto:{{ email }}"
            class="break-all font-medium text-cca-green-600 underline"
            >{{ email }}</a
          >
        </div>
      }
    </div>
  </div>
</div>
