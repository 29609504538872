import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Observable, ReplaySubject, retry, share } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CargoPlannerSDKLoader {
  private document = inject<Document>(DOCUMENT);

  private _observableInstance$: Observable<boolean>;

  /**
   * Sets up our internal (hot) observable
   */
  constructor() {
    const document = this.document;

    this._observableInstance$ = new Observable<boolean>((observer) => {
      // create a script
      const script = document.createElement('script');
      script.id = 'cargoPlannerSDK';
      script.src =
        'https://usermedia.cargoplanner.net/sdk/cargoPlannerSDK.umd.min.js';
      script.async = true;
      script.defer = true;

      // listener for when script does load
      const onLoadListener = () => {
        observer.next(true);
        script.removeEventListener('load', onLoadListener);
        script.removeEventListener('error', onErrorListener);
      };

      // listener for when script fails to load
      const onErrorListener = () => {
        observer.error(false);
        script.removeEventListener('load', onLoadListener);
        script.removeEventListener('error', onErrorListener);

        // remove the script if it failed to load
        script.remove();
      };

      // add listeners
      script.addEventListener('load', onLoadListener);
      script.addEventListener('error', onErrorListener);

      // add script to head
      const head = this.document.getElementsByTagName('head')[0];
      if (head) {
        head.appendChild(script);
      }
    }).pipe(
      // automatically retry up to 3 times
      retry(3),

      // share this observable, only reset when a error happens
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: true,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  /**
   * Calling this and subscribing to it will cause the cargoPlannerSDK to be loaded
   * multiple calls to this will result in the same value (true) and after it has loaded once it will return same value to every observer
   * If it failed to load it will emit a error (false), and we can try again
   */
  load() {
    return this._observableInstance$;
  }
}
