import { InjectionToken } from '@angular/core';
import { OnDemandPreloadService } from './onDemandPreload.service';

export type Preload = (routePath: string) => void;
export const PreloadFunctionToken = new InjectionToken<Preload>(
  'OnDemandPreloadFunction',
);
export const defaultPreloadFunction: (s: OnDemandPreloadService) => Preload = (
  s: OnDemandPreloadService,
) => {
  return (routePath: string) => {
    s.startPreload(routePath);
  };
};
