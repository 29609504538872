import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import {
  ChaincargoApiConfig,
  ChaincargoApiConfigToken,
} from '@cca-common/environment';
import { defaultConfig } from '@cca-common/environment';

@NgModule()
export class ChaincargoModule {
  static forRoot(
    config: Partial<ChaincargoApiConfig> = {},
  ): ModuleWithProviders<ChaincargoModule> {
    const providerList: Provider[] = [
      {
        provide: ChaincargoApiConfigToken,
        useFactory: () => {
          const finalConfig = {
            ...defaultConfig(),
            ...config,
          };
          return finalConfig;
        },
      },
    ];

    return {
      ngModule: ChaincargoModule,
      providers: providerList,
    };
  }
}
