import { NgModule, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { ErrorLogConfig, ErrorLogConfigToken } from '@cca-common/environment';
import { ApplicationErrorHandler } from './error-handler.service';

@NgModule()
export class LoggingDomainModule {
  static forRoot(
    config: ErrorLogConfig,
  ): ModuleWithProviders<LoggingDomainModule> {
    return {
      ngModule: LoggingDomainModule,
      providers: [
        {
          provide: ErrorLogConfigToken,
          useValue: config,
        },
        {
          provide: ErrorHandler,
          useClass: ApplicationErrorHandler,
        },
      ],
    };
  }
}
