<div
  class="flex justify-evenly text-center"
  *transloco="let t; read: 'orderDetail'"
>
  @if (requestedVehicleTypeCanHaveTailLift) {
    <div class="flex w-1/5 flex-col items-center">
      <cca-icon
        class="mb-3 text-xl text-cca-neutrals-300"
        icon="truck-ramp"
      ></cca-icon>
      <span class="pb-1 text-xs text-cca-neutrals-400">{{
        t('driveInfo.isTailliftRequired')
      }}</span>
      <span class="text-sm font-medium text-zinc-500">{{
        order?.tailLiftNeeded
          ? t('driveInfo.yesTailLiftRequired')
          : t('driveInfo.noTailliftRequired')
      }}</span>
    </div>
  }

  @if (minTemperature || maxTemperature) {
    <div class="flex w-1/5 flex-col items-center">
      <cca-icon
        class="mb-3 text-2xl leading-7 text-cca-neutrals-300"
        icon="temperature-3"
      ></cca-icon>
      <span class="pb-1 text-xs text-cca-neutrals-400">{{
        t('driveInfo.minMaxTemperature')
      }}</span>
      <span class="text-sm font-medium text-zinc-500">
        {{ minTemperature | unit }} /
        {{ maxTemperature | unit }}
      </span>
    </div>
  }

  <div class="flex w-1/5 flex-col items-center">
    <cca-icon
      class="mb-3 text-xl text-cca-neutrals-300"
      icon="scale-balanced"
    ></cca-icon>
    <span class="pb-1 text-xs text-cca-neutrals-400">{{
      t('driveInfo.totalWeight')
    }}</span>
    @if (order?.totalWeightMeasurement?.value === 0) {
      <span>-</span>
    } @else {
      <span class="text-sm font-medium text-zinc-500">{{
        (order?.totalWeightMeasurement | unit) ?? 0
      }}</span>
    }
  </div>
</div>
