<div
  class="flex flex-col gap-1 whitespace-nowrap"
  *transloco="let t; read: 'timeWindow.timeOption'"
>
  <p
    class="col-span-3 col-start-2 text-xs text-cca-neutrals-500"
    [class.ml-6]="showClockIcon"
  >
    @if (timeslotRequired) {
      {{ t('timeSlot') }} -
      @if (timeslotPreAgreed) {
        <span>
          {{ t('agreed') }}
        </span>
      }
      @if (!timeslotPreAgreed) {
        <span class="text-cca-orange-800">
          {{ t('toBeDetermined') }}
        </span>
      }
    } @else {
      {{ timeOptionType ? timeOptionTranslation(timeOptionType) : label }}
    }
  </p>
  <div class="flex shrink-0 gap-2 text-cca-neutrals-800">
    @if (showClockIcon) {
      <cca-icon
        icon="clock"
        class="col-span-1 col-start-1 row-span-1 row-start-2 w-4 self-start justify-self-center text-cca-neutrals-800"
      />
    }
    @if (timeWindowStart) {
      <span
        class="col-span-1 col-start-4 row-span-1 row-start-2 shrink-0"
        [ngxTippy]="dateTemplate"
        [tippyClassName]="'cca-tippy'"
        [tippyContext]="{ data: timeWindowStart }"
        [tippyProps]="{
          placement: 'bottom',
          arrow: true,
          theme: 'dark',
          interactive: true,
          appendTo: body,
          zIndex: 999999,
        }"
      >
        {{ timeWindowStart | ccaDate: 'ccc,' }}
        <span>
          @if (showTimeOneLine) {
            {{ timeWindowStart | ccaDate: 'DD • t' }}
          } @else {
            {{ timeWindowStart | ccaDate: 'DD' }}
            <br />
            {{ timeWindowStart | ccaDate: 't' }}
          }
        </span>
      </span>
    } @else {
      <span class="col-span-1 col-start-4 row-span-1 row-start-2 shrink-0">
        <span class="text-cca-neutrals-800">{{ t('toBeDetermined') }}</span>
      </span>
    }

    @if (
      timeOptionType === TimeIndicationType.Between ||
      timeslotRequired ||
      isRange
    ) {
      <cca-icon
        icon="arrow-right"
        class="col-span-1 col-start-3 row-span-1 row-start-2 w-4 self-start text-cca-neutrals-800"
      />
      @if (timeWindowEnd) {
        <span
          class="col-span-1 col-start-4 row-span-1 row-start-2 shrink-0"
          [ngxTippy]="dateTemplate"
          [tippyClassName]="'cca-tippy'"
          [tippyContext]="{ data: timeWindowEnd }"
          [tippyProps]="{
            placement: 'bottom',
            arrow: true,
            theme: 'dark',
            interactive: true,
            appendTo: body,
            zIndex: 999999,
          }"
        >
          {{ timeWindowEnd | ccaDate: 'ccc,' }}
          <span>
            @if (showTimeOneLine) {
              {{ timeWindowEnd | ccaDate: 'DD • t' }}
            } @else {
              {{ timeWindowEnd | ccaDate: 'DD' }}
              <br />
              {{ timeWindowEnd | ccaDate: 't' }}
            }
          </span>
        </span>
      } @else {
        <span class="col-span-1 col-start-4 row-span-1 row-start-2 shrink-0">
          <span class="text-cca-neutrals-800">{{ t('toBeDetermined') }}</span>
        </span>
      }
    }
  </div>
</div>

<ng-template #dateTemplate let-name let-data="data">
  <div class="!flex flex-col gap-1">
    <span>
      {{ data | ccaDate: 'DD' }},
      {{ data | ccaDate: 't' }}
    </span>
    <span>
      {{ data | ccaDate: 'z' }}
    </span>
  </div>
</ng-template>
