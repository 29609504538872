/**
 * Tender API
 * <p>The Tender API is used to manage Tenders</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Pickup  2 = Delivery  4 = Intermediate
 */
export enum StopType {
  Undefined = 0,
  Pickup = 1,
  Delivery = 2,
  Intermediate = 4,
}
