@if (isSmallFormat) {
  <div class="multi-stop group/item px-6 py-2 hover:bg-cca-neutrals-100">
    <div class="flex justify-between gap-2 group-hover/item:text-cca-green-700">
      <ng-template *ngTemplateOutlet="multiStop_Header"></ng-template>
    </div>
    <ng-template *ngTemplateOutlet="multiStop_Content"></ng-template>
  </div>
} @else {
  <ng-template *ngTemplateOutlet="multiStop_Header"></ng-template>
  <ng-template *ngTemplateOutlet="multiStop_Content"></ng-template>
}

<ng-template #multiStop_Header>
  @if (!hideHeader) {
    <h3
      class="mb-1 self-center font-bold"
      [ngClass]="[
        isSmallFormat ? 'text-sm' : 'text-base',
        hasGrayHeader
          ? 'font-normal text-cca-neutrals-500'
          : 'text-cca-green-600',
      ]"
    >
      {{ titleLabel }}
    </h3>
  }
</ng-template>

<ng-template #multiStop_Content>
  @for (item of items; track item; let last = $last) {
    <ng-template
      *ngTemplateOutlet="
        multiStop_ListItem;
        context: { $implicit: item, last: last }
      "
    />
  }
</ng-template>

<ng-template #multiStop_ListItem let-item let-isLast="last">
  @if (item) {
    <div class="group flex items-stretch gap-3">
      <div class="flex shrink-0 flex-col items-center">
        <div
          class="h-1 w-0.5 grow-0"
          [ngClass]="items.length > 1 && isLast ? 'bg-cca-neutrals-300' : ''"
        ></div>
        <img
          class="mx-auto h-3 w-3 grow-0"
          [src]="'/assets/images/booking/location/circle.svg'"
        />
        <div
          class="w-0.5 flex-1 grow"
          [ngClass]="!isLast ? 'bg-cca-neutrals-300' : ''"
        ></div>
      </div>
      <div class="word-break mb-1 flex flex-1 flex-col">
        <span class="w-full text-sm font-medium text-cca-neutrals-800">{{
          item.title
        }}</span>
        <span class="w-full text-sm font-normal text-cca-neutrals-800">
          @if (item.locationName) {
            <span class="w-full text-sm font-bold text-cca-neutrals-800">{{
              item.locationName
            }}</span
            ><br />
          }
          {{ item.address }}</span
        >
      </div>
      @if (isSmallFormat && !hideEdit) {
        <cca-icon
          (click)="edit.emit(item)"
          class="invisible h-fit cursor-pointer text-xs text-cca-green-600 group-hover/item:visible"
          icon="pencil"
        ></cca-icon>
      }
    </div>
  }
</ng-template>
