import { ChainCargoAddress, id } from '@cca-infra/common';
import { StopType } from '@cca-infra/request-management/v1';
import {
  HandleUnitSubType,
  TimeOptionType,
} from '@cca-infra/sequence-management/v1';

export interface UserGroupSummaryValue {
  userGroupId: string;
  userGroupName: string;
  enterpriseGroupId: string;
  enterpriseGroupName: string;
}

export interface SalesOrganizationSummaryValue {
  salesOrganisationName: string;
  salesOrganisationId: string;
  salesOrganisationFlagImageUrl: string;
}

export interface SideSummaryLocationDTO {
  address: ChainCargoAddress;
  index: number;
  locationId: id;
  requiresTimeSlot: boolean;
  stopType: StopType;
}
export interface SideSummaryShipmentStop {
  carrierInstruction: string | null;
  location: ChainCargoAddress | null;
  locationInstruction: string | null;
  locationReference: string | null;
  stopIndex: number;
  stopType: StopType;
  timeslotPreAgreed: boolean;
  timeslotWindowEndTime: number | null;
  timeslotWindowStartTime: number | null;
  windowEndTime: number | null;
  windowStartTime: number | null;
  timingOptionType: TimeOptionType;
}

export interface SideSummaryShipmentsDTO {
  containerDetails: unknown;
  shipperReference: string | null;
  stops: SideSummaryShipmentStop[];
  timeOptionId: id;
}

export interface SideSummaryCargoDTO {
  cargoDimensions: CargoDimension[];
  cargoInformation: {
    estimatedCargoValueId: id;
    description: string;
    temperatureControlled: boolean;
    minTemperature: number | null | undefined;
    maxTemperature: number | null | undefined;
    cargoProductType: CargoProductType;
    hazardousShipment: null;
    hazardCodes: null;
    cargoPalletizeType: CargoPalletizeType;
    cargoLoadingType: CargoLoadingType;
    palletTypeId: id | null;
    maxNumberOfPallets: number | null;
    maxPalletHeight: number | null;
  };
}

export interface CargoDimension {
  handleUnitSubType: HandleUnitSubType;
  handleUnitTypeId: id;
  quantity: number;
  width: number;
  length: number;
  height: number;
  weight: number;
}

export interface SideSummaryVehicleDTO {
  bodyType: string | null;
  bodyTypeName: string | null;
  vehicleTypeId: id;
  vehicleTypeName: string;
}

/** ENUMS */

export enum CargoProductType {
  None,
  Perishable,
  Food,
  FoodAndPerishable = Food | Perishable,
}
export enum CargoPalletizeType {
  None,
  PartOfShipment,
  All,
}

export enum CargoLoadingType {
  None,
  SideLoading,
  DoorLoading,
  TopLoading,
}
