<ng-container *transloco="let t; read: 'richTextViewer'">
  @if (safeText()) {
    @if (large()) {
      <div class="relative bg-white/100" [ngClass]="styling()">
        <div [innerHTML]="safeText()"></div>
      </div>
    } @else {
      @defer {
        <div class="overflow-hidden font-normal">
          <div
            class="relative z-10 max-h-12 overflow-hidden bg-white/100"
            [ngClass]="styling()"
          >
            <div [innerHTML]="safeText()"></div>
          </div>
          <div class="-mt-[4.5rem] flex flex-col">
            <div
              class="max-h-[4.5rem] min-h-12 overflow-hidden"
              [innerHTML]="safeText()"
            ></div>
            <cca-expanded-tooltip-view
              [tooltipBody]="safeText()"
              [tooltipHeaderText]="titleLabel()"
              [handleText]="t('showMoreLabel')"
            >
            </cca-expanded-tooltip-view>
          </div>
        </div>
      }
    }
  }
</ng-container>
