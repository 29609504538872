/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import {
  PreloadingStrategy,
  PRIMARY_OUTLET,
  Route,
  Router,
} from '@angular/router';
import { EMPTY, Observable, Subject, mergeMap, takeUntil } from 'rxjs';
import { OnDemandPreloadService } from './onDemandPreload.service';

interface OnDemandRoute {
  data?: {
    preload?: boolean | undefined;
  };
  path: string;
}

@Injectable({ providedIn: 'root', deps: [OnDemandPreloadService] })
export class OnDemandPreloadStrategy implements PreloadingStrategy {
  private preloadOnDemandService = inject(OnDemandPreloadService);
  private router = inject(Router);

  private preloadPaths$: Observable<string>;

  constructor() {
    this.preloadPaths$ = this.preloadOnDemandService.state;
  }

  preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const conn =
      typeof navigator !== 'undefined'
        ? (navigator as any).connection
        : undefined;
    if (conn) {
      // Don't preload if the user is on 2G. or if Save-Data is enabled..
      if ((conn.effectiveType || '').includes('2g') || conn.saveData)
        return EMPTY;
    }

    if (!this.checkPreloadAvailable(route as OnDemandRoute)) {
      return EMPTY;
    }

    const completed = new Subject<void>();
    return this.preloadPaths$.pipe(
      mergeMap((routePath: string) => {
        if (this.checkRoutePath(route as unknown as OnDemandRoute, routePath)) {
          completed.next();
          completed.complete();
          return load();
        } else {
          return EMPTY;
        }
      }),
      takeUntil(completed),
    );
  }

  checkPreloadAvailable(route: OnDemandRoute) {
    return route?.data && route?.data?.preload;
  }

  private checkRoutePath(route: Route, routePath: string) {
    return findPath(this.router.config, route) === routePath;
  }
}

const findPath = (config: Route[], route: Route): string => {
  config = config.slice();
  const parent = new Map<Route, Route>();
  const visited = new Set<Route>();
  while (config.length) {
    const el = config.shift();
    if (el) {
      visited.add(el);
      if (el === route) break;
      let children = el.children || [];
      const current = (el as any)._loadedConfig;
      if (current && current.routes) {
        children = children.concat(current.routes);
      }
      children.forEach((r: Route) => {
        if (visited.has(r)) return;
        parent.set(r, el);
        config.push(r);
      });
    }
  }
  let path = '';
  let current: Route | undefined = route;

  while (current) {
    if (isPrimaryRoute(current)) {
      path = `/${current.path}${path}`;
    } else {
      path = `/(${current.outlet}:${current.path}${path})`;
    }
    current = parent.get(current);
  }

  return path.replace(/\/\//g, '/');
};

function isPrimaryRoute(route: Route) {
  return (route as any).outlet === PRIMARY_OUTLET || !(route as any).outlet;
}
