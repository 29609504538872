import { Injectable, inject } from '@angular/core';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import * as RouterSelectors from './router.selectors';

@Injectable({
  providedIn: 'root',
})
export class RouterFacade {
  store = inject(Store);
  router = inject(Router);

  /**
   * Basic data from router
   */
  currentRoute$ = this.store.select(RouterSelectors.selectCurrentRoute);
  fragment$ = this.store.select(RouterSelectors.selectFragment);
  queryParams$ = this.store.select(RouterSelectors.selectQueryParams);
  routeParams$ = this.store.select(RouterSelectors.selectRouteParams);
  routeParamsNested$ = this.store.select(
    RouterSelectors.selectRouteNestedParams,
  );
  routeData$ = this.store.select(RouterSelectors.selectRouteData);

  get url() {
    return this.router.url;
  }

  get events() {
    return this.router.events;
  }

  /**
   * constructs a route param stream with param
   */
  routeParam$(param: string) {
    return this.store.select(RouterSelectors.selectRouteParam(param));
  }

  /**
   * constructs a route param stream with param
   */
  routeParamNested$(param: string) {
    return this.store.select(RouterSelectors.selectRouteNestedParam(param));
  }

  /**
   * constructs a query param stream with param
   */
  queryParam$(param: string) {
    return this.store.select(RouterSelectors.selectQueryParam(param));
  }

  /**
   * Delegating navigation to the actual router
   */
  navigateByUrl(
    url: string | UrlTree,
    extras?: NavigationExtras,
  ): Promise<boolean> {
    return this.router.navigateByUrl(url, extras);
  }

  /**
   * Delegating navigation to the actual router
   */
  navigate(commands: unknown[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }

  /**
   * Delegating parseUrl to the actual router
   */
  parseUrl(url: string): UrlTree {
    return this.router.parseUrl(url);
  }

  /**
   * Delegating createUrlTree to the actual router
   */
  createUrlTree(commands: unknown[]): UrlTree {
    return this.router.createUrlTree(commands);
  }

  /**
   * Delegating serializeUrl to the actual router
   */
  serializeUrl(url: UrlTree): string {
    return this.router.serializeUrl(url);
  }

  /**
   * Delegating isActive to the actual router
   */
  isActive(url: string | UrlTree, exact: boolean): boolean {
    return this.router.isActive(url, exact);
  }

  /**
   * Delegating getCurrentNavigation to the actual router
   */
  getCurrentNavigation() {
    return this.router.getCurrentNavigation();
  }
}
