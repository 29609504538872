import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OnDemandPreloadService {
  private loaded = new Set<string>();
  private subject = new Subject<string>();
  state = this.subject.asObservable();

  startPreload(routePath: string) {
    if (!this.loaded.has(routePath)) {
      this.subject.next(routePath);
      this.loaded.add(routePath);
    }
  }
}
