<div
  [ngClass]="{
    primary: flavor === 'primary',
    warning: flavor === 'warning',
    danger: flavor === 'danger',
    highlight: flavor === 'highlight',
    default: flavor === 'default',
  }"
  [@inOutAnimation]
  class="flex items-center gap-2 rounded-lg px-4 py-3"
>
  @if (showIcon) {
    <cca-icon
      class="text-2xl leading-6 text-cca-neutrals-800"
      [icon]="flavor === 'danger' ? 'circle-exclamation' : 'info'"
    ></cca-icon>
  }
  <p class="text-base text-cca-neutrals-800">
    {{ description }}
  </p>

  @if (closable) {
    <button mat-icon-button (click)="close()" class="ml-2">
      <cca-icon
        icon="circle-xmark"
        class="text-xl !text-cca-neutrals-800"
      ></cca-icon>
    </button>
  }
</div>
