import { HttpContextToken } from '@angular/common/http';

export const AuthenticationContextToken = new HttpContextToken<boolean>(
  () => true,
);

export const PaginationContextToken = new HttpContextToken<boolean>(
  () => false,
);

export const ServiceWorkerContextToken = new HttpContextToken<boolean>(
  () => false,
);

export const NoAccessContextToken = new HttpContextToken<boolean>(() => true);

export const NotFoundContextToken = new HttpContextToken<boolean>(() => true);
