/**
 * Order API
 * <p>The Order API is used to manage Orders within the platform</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Warehouse  2 = Business  3 = Address  4 = Other
 */
export enum LocationType {
  Undefined = 0,
  Warehouse = 1,
  Business = 2,
  Address = 3,
  Other = 4,
}
