import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterFacade } from './router.facade';

@NgModule()
export class RouterStoreModule {
  static forRoot(): ModuleWithProviders<RouterStoreModule> {
    return {
      ngModule: RouterStoreModule,
      providers: [RouterFacade],
    };
  }
}
