import { Directive, ElementRef, Input, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { PreloadFunctionToken, Preload } from './onDemandPreload.function';

@Directive({
  selector: '[ccaPreload]',
  standalone: true,
})
export class PreloadDirective implements OnDestroy {
  private element = inject(ElementRef);
  notifyPreloadService = inject<Preload>(PreloadFunctionToken);

  @Input() ccaPreload: string | undefined;
  private currentPath: string;

  constructor() {
    const activatedRoute = inject(ActivatedRoute);

    this.currentPath = activatedRoute.snapshot.pathFromRoot.reduce(
      (acc: string, cur: ActivatedRouteSnapshot) =>
        (acc += cur.url.reduce((acc2, cur2) => (acc2 += '/' + cur2.path), '')),
      '',
    );

    (this.element.nativeElement as HTMLElement).addEventListener(
      'mouseover',
      this.onMouseOver.bind(this),
      false,
    );
  }

  onMouseOver() {
    if (this.ccaPreload)
      this.notifyPreloadService(this.currentPath + this.ccaPreload);
    (this.element.nativeElement as HTMLElement).removeEventListener(
      'mouseover',
      this.onMouseOver,
    );
  }

  ngOnDestroy() {
    (this.element.nativeElement as HTMLElement).removeEventListener(
      'mouseover',
      this.onMouseOver,
    );
  }
}
