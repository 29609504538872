<section class="flex flex-col gap-2">
  <h3 class="text-base text-cca-green-600">
    {{ title }}
  </h3>
  <div
    class="mr-1 flex items-center gap-1 text-sm font-medium text-cca-neutrals-800"
  >
    <ng-content></ng-content>
  </div>
</section>
