import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { LetDirective } from '@ngrx/component';
import { StopType } from '@cca-infra/order-management/v2';
import { IconComponent } from '../icon';

export interface MultiStop {
  index: number;
  stopType: StopType;
  stepIteration: number;
  title: string;
  address: string | undefined;
}

@Component({
  selector: 'cca-multi-stop-list',
  standalone: true,
  templateUrl: './multi-stop-list.component.html',
  styleUrls: ['./multi-stop-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    LetDirective,
    NgTemplateOutlet,
    MatExpansionModule,
    IconComponent,
  ],
})
export class MultiStopListComponent implements OnChanges {
  panelOpenState = false;
  intermediaryItems: MultiStop[] = [];
  firstExpandedItem: MultiStop | undefined;

  @Input() titleLabel: string | undefined;
  @Input() items: MultiStop[] = [];
  @Input() isSmallFormat = false;
  @Input() collapsedHeaderItem: MultiStop | undefined;
  @Input() hideHeader = false;
  @Input() hideEdit = false;
  @Input() hasGrayHeader = false;

  @Output() edit = new EventEmitter<MultiStop>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.intermediaryItems = [];
      this.firstExpandedItem = undefined;
      if (!this.isSmallFormat || this.items.length <= 3) {
        return;
      }
      this.intermediaryItems = this.items.filter(
        (l) => l.index > 1 && l.index !== this.items.length - 1,
      );
      this.firstExpandedItem = this.items.find((l) => l.index === 1);
    }
  }
}
