<div
  class="flex h-full flex-row justify-start gap-4 rounded border border-cca-neutrals-300 bg-white p-4"
  [class.active]="active"
  (click)="selected.next()"
>
  <div class="flex h-16 w-16 content-center justify-center">
    <img class="shrink-0" [src]="imageSrc" />
  </div>

  <div class="flex flex-col justify-center gap-1">
    <h4 class="text-base font-bold text-cca-neutrals-800">
      {{ translations?.title }}
    </h4>
    <p class="text-xs font-normal text-cca-neutrals-500">
      {{ translations?.description }}
    </p>
  </div>
</div>
