import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { BaseApiService, HttpContextBuilder } from '@cca-infra/core';
import { AuthViewModel } from '../model';
import { Base64 } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseApiService {
  constructor() {
    super(`user.v1.authentication`);
  }

  login(username: string, password: string): Observable<AuthViewModel> {
    return this.http.post<AuthViewModel>(
      `${this.url}cookie`,
      {
        timeZoneOffset: new Date().getTimezoneOffset() * -1,
        timeZoneId: this.timezone,
      },
      {
        headers: {
          authorization: 'Basic ' + Base64.encode(`${username}:${password}`),
        },
        withCredentials: true,
        context: HttpContextBuilder({
          authentication: false,
        }),
      },
    );
  }

  refreshAccessToken(userId: string): Observable<AuthViewModel> {
    return this.http.post<AuthViewModel>(
      `${this.url}refresh`,
      {
        userId: userId,
        timeZoneOffset: new Date().getTimezoneOffset() * -1,
        timeZoneId: this.timezone,
      },
      {
        withCredentials: true,
        context: HttpContextBuilder({
          authentication: false,
        }),
      },
    );
  }

  loginSso(accessToken: string): Observable<AuthViewModel> {
    return this.http.get<AuthViewModel>(`${this.url}internal-cookie`, {
      headers: {
        'Aad-Authorization': accessToken,
      },
      withCredentials: true,
      context: HttpContextBuilder({
        authentication: false,
      }),
    });
  }

  get timezone() {
    return new Intl.DateTimeFormat('en-US').resolvedOptions().timeZone;
  }

  logout(): Observable<void> {
    return this.http
      .post<void>(
        `${this.url}logout`,
        {},
        {
          withCredentials: true,
          context: HttpContextBuilder({
            authentication: false,
          }),
        },
      )
      .pipe(catchError(() => of()));
  }
}
