import {
  Pipe,
  PipeTransform,
  OnDestroy,
  Injectable,
  inject,
} from '@angular/core';
import { BaseLocalePipe } from '@jsverse/transloco-locale';
import { Subscription } from 'rxjs';
import { NumberPipeFormatterCache } from './number.pipe';

@Injectable({
  providedIn: 'root',
})
class CurrencyPipeFormatterCache {
  private formatterCache = new Map<string, Map<string, Intl.NumberFormat>>();

  resolveFormatter(locale: string, currency: string) {
    const currencyFormatterMap = this.resolveLocaleMap(locale);

    // check if a formatter exists within the currencyFormatterMap
    // create a new one if it does not
    if (!currencyFormatterMap.has(currency)) {
      return this.createAndStoreFormatter(
        locale,
        currency,
        currencyFormatterMap,
      );
    }

    // return the formatter from the map
    return currencyFormatterMap.get(currency) as Intl.NumberFormat;
  }

  private resolveLocaleMap(locale: string): Map<string, Intl.NumberFormat> {
    // when the cache does not have a map for the requested locale create one
    // and set it in the cache
    if (!this.formatterCache.has(locale)) {
      return this.createAndStoreLocaleMap(locale);
    }

    // return the currencyFormatMap value, we can be sure it does exist since above code would set it.
    return this.formatterCache.get(locale) as Map<string, Intl.NumberFormat>;
  }

  private createAndStoreLocaleMap(locale: string) {
    // create new map for storing formatter for locale
    const newLocaleMap = new Map<string, Intl.NumberFormat>();

    // save the map within the formatterMapCache
    this.formatterCache.set(locale, newLocaleMap);

    return newLocaleMap;
  }

  private createAndStoreFormatter(
    locale: string,
    currency: string,
    currencyFormatterMap: Map<string, Intl.NumberFormat>,
  ) {
    // create new number currency formatter
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    });

    // save the formatter in the currencyFormatterMap
    currencyFormatterMap.set(currency, formatter);

    // return reference to the formatter
    return formatter;
  }
}

@Pipe({
  standalone: true,
  name: 'currency',
  pure: false,
})
export class CdkCurrencyPipe
  extends BaseLocalePipe
  implements PipeTransform, OnDestroy
{
  formatterCache = inject(CurrencyPipeFormatterCache);
  formatterCacheNumber = inject(NumberPipeFormatterCache);
  localeSubscription: Subscription | null;
  constructor() {
    super();

    // when locale changes make sure to mark the current view as dirty
    this.localeSubscription = this.localeService.localeChanges$.subscribe(
      () => {
        this.cdr.markForCheck();
      },
    );
  }

  transform(
    value: number | null | undefined,
    currency: string | null | undefined,
  ): string {
    if (typeof value !== 'number') {
      return '';
    }

    if (currency) {
      // resolve a formatter
      const formatter = this.formatterCache.resolveFormatter(
        this.localeService.getLocale(),
        currency,
      );

      // use formatter to format the value
      return formatter.format(value);
    }

    // backup use a formatter to format number only
    const formatter = this.formatterCacheNumber.resolveFormatter(
      this.localeService.getLocale(),
    );

    return formatter.format(value);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.localeSubscription?.unsubscribe();
    this.localeSubscription = null;
  }
}

@Pipe({
  standalone: true,
  name: 'currencySymbol',
  pure: false,
})
export class CdkCurrencySymbolPipe
  extends BaseLocalePipe
  implements PipeTransform, OnDestroy
{
  private formatterCache = inject(CurrencyPipeFormatterCache);

  localeSubscription: Subscription | null;
  constructor() {
    super();

    // when locale changes make sure to mark the current view as dirty
    this.localeSubscription = this.localeService.localeChanges$.subscribe(
      () => {
        this.cdr.markForCheck();
      },
    );
  }

  transform(currency: string | null | undefined) {
    if (currency) {
      // resolve a formatter
      const formatter = this.formatterCache.resolveFormatter(
        this.localeService.getLocale(),
        currency,
      );

      // use formatter to format a number to parts with value 0
      // this creates a array of parts with { type: 'currency' | 'integer' | 'decimal' | 'fraction'}
      // we are interested in the currency part of this, this will hold the symbol for the currency
      return formatter.formatToParts(0).find((part) => part.type === 'currency')
        ?.value;
    }
    return '';
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.localeSubscription?.unsubscribe();
    this.localeSubscription = null;
  }
}
