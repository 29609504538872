<ng-container *transloco="let t; read: 'eventLog'">
  <table class="w-full" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="entityType">
      <th mat-header-cell *matHeaderCellDef>{{ t('entityType') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.entityType }}
      </td>
    </ng-container>

    <ng-container matColumnDef="objectName">
      <th mat-header-cell *matHeaderCellDef>{{ t('objectName') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.objectName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fieldName">
      <th mat-header-cell *matHeaderCellDef>{{ t('fieldName') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.fieldName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="oldValue">
      <th mat-header-cell *matHeaderCellDef>
        {{ t('oldValue') }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="whitespace-pre-line break-all"
      >
        @if (containsHTML(element.oldDisplayValue)) {
          <cca-rich-text-viewer
            [text]="element.oldDisplayValue ?? '-'"
            [titleLabel]="'comment'"
          >
          </cca-rich-text-viewer>
        } @else {
          {{ element.oldDisplayValue }}
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="newValue">
      <th mat-header-cell *matHeaderCellDef>
        {{ t('newValue') }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="whitespace-pre-line break-all"
      >
        @if (containsHTML(element.newDisplayValue)) {
          <cca-rich-text-viewer
            [text]="element.newDisplayValue ?? '-'"
            [titleLabel]="'comment'"
          >
          </cca-rich-text-viewer>
        } @else {
          {{ element.newDisplayValue }}
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="translatedIdentifier">
      <th mat-header-cell *matHeaderCellDef>
        {{ t('translatedIdentifier') }}
      </th>
      <td mat-cell *matCellDef="let element" class="break-all">
        {{ element.translatedIdentifier }}
      </td>
    </ng-container>

    <ng-container matColumnDef="changedBy">
      <th mat-header-cell *matHeaderCellDef>
        {{ t('changedBy') }}
      </th>
      <td mat-cell *matCellDef="let element">
        @if (element.firstName || element.lastName) {
          {{ element.firstName }} {{ element.lastName }}
        }
        @if (!element.firstName && !element.lastName) {
          {{ element.saveSourceType }}
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="auditTimeStamp">
      <th mat-header-cell *matHeaderCellDef>
        {{ t('auditTimeStamp') }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.auditTimeStampMilliseconds | ccaDate: 'ff' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
    ></tr>
  </table>
</ng-container>
