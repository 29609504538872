<div class="relative flex flex-col items-center gap-1 pt-1">
  <div class="flex w-40 items-center">
    <span
      class="flex h-4 w-4 shrink-0 rounded-full border-2 border-solid border-cca-neutrals-500 bg-cca-neutrals-50"
    ></span>
    <span
      class="flex h-1 w-full items-center justify-center bg-cca-neutrals-300"
    >
      @if (numberOfStops > 2) {
        <span
          class="bg-cca-neutrals-50 p-1 text-sm font-medium text-cca-green-600"
          >+{{ numberOfStops - 2 }} {{ stopsText }}</span
        >
      }
    </span>
    <span
      class="flex h-4 w-4 shrink-0 rounded-full border-2 border-solid border-cca-neutrals-500 bg-cca-neutrals-50"
    ></span>
  </div>
  <span class="text-sm font-medium text-cca-purple-500">
    {{ text }}
  </span>
</div>
