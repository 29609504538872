<!-- <ng-container *transloco="let t; read: 'image'" > -->
<div class="dialog-container">
  <header>
    <h2>
      {{ 'image.editHeader' | transloco }}
    </h2>
    <button [mat-dialog-close]="null" class="dialog-close-button">
      <cca-icon icon="xmark"></cca-icon>
    </button>
  </header>
  <main>
    <h5>
      {{ 'image.editText' | transloco }}
    </h5>
    <br />
    <div class="w-[600px]">
      <ly-img-cropper
        [config]="myConfig"
        [(scale)]="scale"
        (ready)="onReady()"
        (minScale)="minScale = $event"
        (cropped)="onCropped($event)"
        (loaded)="onLoaded($event)"
      >
        <div class="align-items flex flex-col justify-center">
          <div class="self-center">
            <cca-icon
              class="text-4xl text-zinc-400"
              icon="folder-open"
            ></cca-icon>
          </div>
          <span class="text-cca-red-500">
            {{ 'image.formatError' | transloco }}
          </span>
        </div>
      </ly-img-cropper>
    </div>
    <br />
    @if (ready) {
      <div class="flex flex-row justify-between">
        <div class="flex flex-row items-center gap-8">
          @if (ready) {
            <div class="flex flex-row items-center gap-2">
              <div class="text-sm text-zinc-700">0%</div>
              <ly-slider
                class="!mb-0 !w-[150px]"
                [marks]="true"
                [thumbVisible]="false"
                [min]="minScale"
                [max]="1"
                [size]="'medium'"
                [(ngModel)]="scale"
                (input)="onSliderInput($event)"
                [color]="'#62732e'"
                step="0.000001"
              >
              </ly-slider>
              @if (math.round(zoomValue) !== 0) {
                <div class="text-sm text-zinc-700">
                  {{ math.round(zoomValue) }} %
                </div>
              }
            </div>
          }
          <div>
            <cca-icon
              title="Rotate"
              class="cursor-pointer hover:text-cca-green-400"
              (click)="cropper?.rotate(-90)"
              icon="rotate"
            ></cca-icon>
          </div>
        </div>
      </div>
    }
  </main>
  <footer>
    <button (click)="reset()" mat-stroked-button color="primary">
      {{ 'image.reset' | transloco }}
    </button>
    <button mat-flat-button color="primary" (click)="saveCrop()">
      {{ 'image.save' | transloco }}
    </button>
  </footer>
</div>
<!-- </ng-container> -->
